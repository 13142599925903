import { FC } from 'react';

const TwitterIcon: FC = () => {
  return (
    <svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.0146 2.7025C19.7006 2.84184 19.3778 2.95945 19.0479 3.05496C19.4384 2.61301 19.7362 2.09301 19.9179 1.52399C19.9587 1.39645 19.9165 1.25684 19.8117 1.17341C19.707 1.08993 19.5616 1.07993 19.4464 1.14825C18.7459 1.56399 17.9901 1.86278 17.1976 2.03747C16.3993 1.25696 15.3151 0.812744 14.1942 0.812744C11.8282 0.812744 9.90336 2.73875 9.90336 5.10608C9.90336 5.29253 9.91515 5.47796 9.9385 5.66081C7.00256 5.40288 4.27306 3.95894 2.39874 1.65837C2.33194 1.57637 2.22903 1.53216 2.12367 1.54063C2.01827 1.54887 1.92352 1.60837 1.87027 1.69981C1.49011 2.3525 1.28914 3.09902 1.28914 3.85859C1.28914 4.89312 1.65825 5.87464 2.31031 6.64163C2.11204 6.57291 1.91965 6.48706 1.73602 6.38506C1.63744 6.33014 1.51713 6.331 1.41918 6.38717C1.32119 6.44338 1.25974 6.5467 1.25713 6.65967C1.25666 6.6787 1.25666 6.69772 1.25666 6.71702C1.25666 8.26119 2.08725 9.65146 3.35713 10.4092C3.24802 10.3983 3.13903 10.3825 3.03069 10.3618C2.919 10.3404 2.80419 10.3796 2.72889 10.4648C2.65346 10.55 2.62848 10.6687 2.66326 10.7771C3.13329 12.2455 4.34344 13.3255 5.80642 13.6548C4.59302 14.4153 3.20543 14.8136 1.74878 14.8136C1.44483 14.8136 1.13915 14.7957 0.840002 14.7603C0.691382 14.7426 0.549281 14.8304 0.498648 14.9719C0.448015 15.1133 0.501693 15.2711 0.6281 15.3522C2.49942 16.5528 4.66317 17.1873 6.88537 17.1873C11.2539 17.1873 13.9868 15.126 15.51 13.3968C17.4095 11.2406 18.4989 8.38666 18.4989 5.56675C18.4989 5.44894 18.4971 5.32999 18.4935 5.2114C19.2429 4.64644 19.8881 3.96269 20.4131 3.17684C20.4929 3.0575 20.4842 2.89973 20.3918 2.78988C20.2997 2.67985 20.1459 2.6443 20.0146 2.7025Z" fill="#BAA57E"/>
    </svg>
  )
}

export default TwitterIcon;
